
.jumbotron {
  background-image: url("../../../Assets/smoke.jpg");
  height: 100vh;
  /* width: 100vw; */
  max-width: 100%;
  /* paddingBottom: theme.spacing(6), */
  display: flex;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* flexWrap: "wrap"; */
  flex-direction: column;
}


h1.MuiTypography-root.title.MuiTypography-h1 {
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  animation: flow 15s ease-in-out infinite;
  background: linear-gradient(
    -60deg,
    #032136,
    #b0c4de,
    #032136,
    #b0c4de,
    #032136
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300%;
}
@media (max-width: 820px) {
  h1.MuiTypography-root.title.MuiTypography-h1 {
    font-size: 6em;
    /* margin: auto 0;
    justify-content: center; */
  }
}
@media (max-width: 400px) {
  h1.MuiTypography-root.title.MuiTypography-h1 {
    font-size: 4em;
    margin: auto 0;
    /* justify-content: center; */ 
  }
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.smokescreen {
  height: 100vh;
  width: 100%;
  z-index: 1;
}
/* Free B-Roll provided by <a href="http://www.videezy.com/">Videezy</a> */

.arrow {
  text-align: center;
  margin-bottom: 10em;
  display: inline-block;
}

.arrow svg {
  border-radius: 50%;
  transition: 0.3s;
  padding: .1em
  /* height: 4em */
}

.arrow svg:hover {
  background-color: #b0c4de80;
  color: #032136;
  cursor: pointer;
}

.bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
